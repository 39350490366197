import * as tslib_1 from "tslib";
import { Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from '../../classes/base/second-page-edit-base';
import { ProvincesService } from '../../../dapfood/services/provinces.service';
import { DistrictsService } from '../../../dapfood/services/districts.service';
import { CustomerTypesService } from '../../../dapfood/services/customerTypes.service';
import { WardsService } from '../../../dapfood/services/wards.service';
import { UserService } from '../../services/user.service';
import { ConfigurationService } from '../../services/configuration.service';
import { Utilities } from '../../../shared/utilities';
import { CustomerTypeOption, PositionType, UserType } from '../../../dapfood/common/constant';
import { UsersTempService } from '../../../dapfood/services/userstemp.service';
import { OrganizationsService } from '../../../dapfood/services/organizations.service';
import { ClientsService } from '../../../dapfood/services/clients.service';
import * as moment from 'moment';
export class UserInformationComponent extends SecondPageEditBase {
    constructor(_injector, _ProvincesService, _DistrictsService, _customerTypesService, _WardsService, _UserService, _UsersTempService, _configurationService, _OrganizationService, _ClientService) {
        super(null, _injector);
        this._injector = _injector;
        this._ProvincesService = _ProvincesService;
        this._DistrictsService = _DistrictsService;
        this._customerTypesService = _customerTypesService;
        this._WardsService = _WardsService;
        this._UserService = _UserService;
        this._UsersTempService = _UsersTempService;
        this._configurationService = _configurationService;
        this._OrganizationService = _OrganizationService;
        this._ClientService = _ClientService;
        this.isShow = false;
        this.customerTypes_options = [];
        this.userGroups_options = [];
        this.organization_options = [];
        this.pharmacyType_options = [];
        this.tdvGroups_options = [];
        this.client_options = [];
        this.province_options = [];
        this.district_options = [];
        this.ward_options = [];
        this.placeOfIssueDkdn_options = [];
        this.isLoading = false;
        this.submitted = false;
        this.isEditCode = false;
        this.modelEdit = {
            userId: 0,
            name: "",
            idType: -1
        };
        this.isCreate = false;
        this.userType = UserType;
        this.accountType_options = [];
        this.postionType_options = [];
        this.isShowAccountType = false;
        this.typeUser = -1;
        this.tdvGroups = [];
        this.isGetLocation = false;
        this.addressLatLng = "";
        this.isFormTdv = false;
        this.titleHeader = "Thêm/Sửa nhà thuốc";
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.init();
        });
    }
    init() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.vi = this._configurationService.calendarVietnamese;
            this.formGroup = new FormGroup({
                name: new FormControl('', Validators.compose([Validators.required])),
                phone: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
                password: new FormControl(''),
                email: new FormControl(''),
                code: new FormControl(''),
                avatar: new FormControl(''),
                legalRepresentatives: new FormControl(''),
                pharmacistPtcm: new FormControl(''),
                idCardPtcm: new FormControl(''),
                phonePtcm: new FormControl(''),
                idProvince: new FormControl(''),
                idDistrict: new FormControl(''),
                idWard: new FormControl(''),
                address: new FormControl(''),
                fullAddress: new FormControl(''),
                certificateNumberDkdn: new FormControl(''),
                certificateDkdnDate: new FormControl(''),
                placeOfIssueDkdn: new FormControl(''),
                certificateDkdn: new FormControl(''),
                certificateNumberDdkkd: new FormControl(''),
                certificateDdkkdDate: new FormControl(''),
                placeOfIssueDdkkd: new FormControl(''),
                certificateDdkkd: new FormControl(''),
                certificateNumberGdpGppDsp: new FormControl(''),
                certificateGdpGppDspExpirationDate: new FormControl(''),
                certificateGdpGppDspDate: new FormControl(''),
                placeOfIssueGdpGppDsp: new FormControl(''),
                certificateGdpGppDsp: new FormControl(''),
                idPharmacyType: new FormControl('', Validators.compose([Validators.required])),
                invoiceCompanyName: new FormControl(''),
                invoiceEmail: new FormControl(''),
                invoiceTaxCode: new FormControl(''),
                invoiceAddress: new FormControl(''),
                userCustomerTypes: new FormControl(''),
                userName: new FormControl(''),
                passwordRepeat: new FormControl(''),
                idCustomerType: new FormControl(''),
                idGroup: new FormControl(''),
                idType: new FormControl(''),
                lat: new FormControl(''),
                lng: new FormControl(''),
                idOrganization: new FormControl(''),
                isLeader: new FormControl(''),
                tdvGroups: new FormControl(''),
                idClient: new FormControl(''),
                idPosition: new FormControl(''),
                codeMapInvoice: new FormControl(''),
                certificateImages: new FormControl('')
            });
            yield this.loadUserTypes(); // Loại khách hàng
            yield this.loadPharmacyTypes();
            yield this.loadOrganization();
            yield this.loadClient();
            this.accountType_options = [
                { label: 'Loại tài khoản', value: -1 },
                { label: 'Nhà thuốc', value: UserType.NhaThuoc },
                { label: 'Trình dược viên', value: UserType.TrinhDuocVien },
                { label: 'Doanh nghiệp', value: UserType.DoanhNghiep },
                { label: 'Kho', value: UserType.Kho },
            ];
            this.postionType_options = [
                { label: 'Admin Mypha', value: PositionType.ChuSoHuu },
                { label: 'Quản lý (ASM/RSM)', value: PositionType.GiamSat },
                { label: 'SalesForce (Trình dược viên)', value: PositionType.NhanVien },
                { label: 'DVKH', value: PositionType.DVKH },
                { label: 'CSTDV', value: PositionType.CSTDV },
                { label: 'CSKH', value: PositionType.CSKH },
                { label: 'Kế toán', value: PositionType.KeToan },
                { label: 'NCC', value: PositionType.NCC },
                { label: 'Khác', value: PositionType.Other },
            ];
        });
    }
    showPopup(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log(data);
            this.isEditCode = data.id > 0 ? true : false;
            this.addressLatLng = '';
            this.modelEdit = {};
            this.isShow = true;
            this.submitted = false;
            this.modelEdit.idType = data.idType;
            this.typeUser = data.type;
            this.tdvGroups = [];
            this.isShowAccountType = data.isShowAccountType;
            yield this.onLoadProvinces();
            if (data.id > 0) {
                this.formGroup.get('password').clearValidators();
                this.formGroup.get('password').updateValueAndValidity();
                yield this._UserService.GetByUserId(data.id).then((rs) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (rs.status) {
                        this.modelEdit = rs.data;
                        if (this.modelEdit.certificateDkdnDate) {
                            this.modelEdit.certificateDkdnDate = new Date(this.modelEdit.certificateDkdnDate);
                        }
                        if (this.modelEdit.certificateGdpGppDspDate) {
                            this.modelEdit.certificateGdpGppDspDate = new Date(this.modelEdit.certificateGdpGppDspDate);
                        }
                        if (this.modelEdit.certificateGdpGppDspExpirationDate) {
                            this.modelEdit.certificateGdpGppDspExpirationDate = new Date(this.modelEdit.certificateGdpGppDspExpirationDate);
                        }
                        if (this.modelEdit.certificateDdkkdDate) {
                            this.modelEdit.certificateDdkkdDate = new Date(this.modelEdit.certificateDdkkdDate);
                        }
                    }
                }), () => {
                    this._notifierService.showHttpUnknowError();
                });
                // if (data.tdvEditTemp != null && data.tdvEditTemp > 0) {
                //   this.formGroup.addControl('isEditTdvTemp ', new FormControl(data.tdvEditTemp));
                //   this.modelEdit.isEditTdvTemp = data.tdvEditTemp
                // }
                if (this.modelEdit.userOrganizations) {
                    this.modelEdit.userOrganizations.forEach(element => {
                        this.tdvGroups.push(Number(element.idOrganization));
                    });
                }
            }
            else {
                this.formGroup.get('password').setValidators([Validators.required, Validators.minLength(6)]);
                this.formGroup.get('password').updateValueAndValidity();
                this.modelEdit = {
                    userId: 0,
                    idType: data.idType
                };
                this.isCreate = true;
            }
            if (this.modelEdit.idType === UserType.NhaThuoc) {
                this.formGroup.get('idPharmacyType').setValidators([Validators.required]);
            }
            else {
                this.formGroup.get('idPharmacyType').clearValidators();
            }
            this.formGroup.get('idPharmacyType').updateValueAndValidity();
            if (data.idType === -1 && data.id <= 0) {
                this.formGroup.get('accountType').setValidators([Validators.required]);
                this.formGroup.get('accountType').updateValueAndValidity();
            }
            if (this.modelEdit.idType === UserType.NhaThuoc)
                yield this.loadUserGroupsNT();
            this.onLoadDistricts();
            this.onLoadWards();
            this.fn_CheckLocationLatLng(this.modelEdit.lat, this.modelEdit.lng);
        });
    }
    // cơ cấu tổ chức
    loadOrganization() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.organization_options = [];
            yield this._OrganizationService.GetShort('', 0, 10000, '').then(rs => {
                if (rs.status) {
                    rs.data.forEach(item => {
                        const treeData = this.buildTree(rs.data);
                        this.organization_options = this.flattenTree(treeData);
                        // this.organization_options.push({ label: item.name, value: item.id });
                    });
                }
            });
        });
    }
    //loại khách hàng
    loadUserTypes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.customerTypes_options = [];
            yield this._customerTypesService.GetShort('', CustomerTypeOption.IdCustomerType, 0, 10000).then(rs => {
                if (rs.status) {
                    let dataArr = [];
                    rs.data.forEach(item => {
                        dataArr.push({ label: item.name, value: item.id });
                    });
                    this.customerTypes_options = dataArr;
                }
            });
        });
    }
    //loại hình nhà thuốc
    loadPharmacyTypes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.pharmacyType_options = [];
            yield this._customerTypesService.GetShort('', CustomerTypeOption.idPharmacyType, 0, 10000).then(rs => {
                if (rs.status) {
                    rs.data.forEach(item => {
                        this.pharmacyType_options.push({ label: item.name, value: item.id });
                    });
                }
            });
        });
    }
    //doanh nghiệp
    loadClient() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.client_options = [];
            yield this._ClientService.GetShort('').then(rs => {
                if (rs.status) {
                    rs.data.forEach(item => {
                        this.client_options.push({ label: item.name, value: item.id });
                    });
                }
            });
        });
    }
    onLoadDistricts() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this._DistrictsService.GetShort(this.modelEdit.idProvince).then(rs => {
                if (rs.status) {
                    this.district_options = rs.data;
                }
            });
        });
    }
    onLoadProvinces() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this._ProvincesService.GetShort().then(rs => {
                if (rs.status) {
                    this.province_options = rs.data;
                    this.placeOfIssueDkdn_options = rs.data;
                }
            });
        });
    }
    onLoadWards() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this._WardsService.GetShort(this.modelEdit.idDistrict).then(rs => {
                if (rs.status) {
                    this.ward_options = rs.data;
                }
            });
        });
    }
    onSwitchChange(isLeader) {
        this.modelEdit.isLeader = isLeader ? isLeader : false;
    }
    //nhóm khách hàng
    loadUserGroupsNT() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this._customerTypesService.GetShort('', CustomerTypeOption.IdGroupNT, 0, 10000).then(rs => {
                if (rs.status) {
                    var list = [];
                    rs.data.forEach(item => {
                        list.push({ label: item.name, value: item.id });
                    });
                    this.userGroups_options = list;
                }
            });
        });
    }
    save() {
        this.isLoading = true;
        this.submitted = true;
        this.modelEdit.userOrganizations = [];
        if (this.formGroup.valid) {
            this.modelEdit.username = this.modelEdit.phone; // this.f.userName.setValue(this.f.phone.value);
            this.modelEdit.passwordRepeat = this.modelEdit.password; //this.f.passwordRepeat.setValue(this.f.password.value);
            let _userCustomerTypes = [];
            this.modelEdit.userCustomerTypes = _userCustomerTypes; //this.f.userCustomerTypes.setValue(_userCustomerTypes);
            if (this.tdvGroups && this.tdvGroups.length > 0) {
                this.tdvGroups.forEach(g => {
                    this.modelEdit.userOrganizations.push({ userId: this.modelEdit.userId, idOrganization: g });
                });
            }
            if (this.typeUser === UserType.NhaThuoc) {
                if (moment(this.modelEdit.certificateDkdnDate).isValid()) {
                    this.modelEdit.certificateDkdnDate = moment(this.modelEdit.certificateDkdnDate).format('YYYY-MM-DD HH:mm');
                }
                if (moment(this.modelEdit.certificateDdkkdDate).isValid()) {
                    this.modelEdit.certificateDdkkdDate = moment(this.modelEdit.certificateDdkkdDate).format('YYYY-MM-DD HH:mm');
                }
                if (moment(this.modelEdit.certificateGdpGppDspDate).isValid()) {
                    this.modelEdit.certificateGdpGppDspDate = moment(this.modelEdit.certificateGdpGppDspDate).format('YYYY-MM-DD HH:mm');
                }
                if (moment(this.modelEdit.certificateGdpGppDspExpirationDate).isValid()) {
                    this.modelEdit.certificateGdpGppDspExpirationDate = moment(this.modelEdit.certificateGdpGppDspExpirationDate).format('YYYY-MM-DD HH:mm');
                }
            }
            this._UserService.post(this.modelEdit).then((response) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (response.status) {
                    if (response.data != null && response.data.userId > 0) {
                        this.modelEdit.userId = response.data.userId;
                        this.modelEdit.isDeleted = 0;
                        this._notifierService.showSuccess(response.message);
                        // if (this.isCreate) {
                        //   await this._UserService.changeIdType(this.modelEdit.userId, this.modelEdit.idType).then(rs => {
                        //     if (rs.status) {
                        //       this._notifierService.showSuccess(rs.message);
                        //     } else {
                        //       this._notifierService.showError(rs.message);
                        //     }
                        //   })
                        //     .catch(err => {
                        //       this._notifierService.showWarning(Utilities.getErrorDescription(err));
                        //     });
                        // }
                        this.closePopupMethod(response.data);
                    }
                }
                else {
                    this.isLoading = false;
                    this._notifierService.showWarning('Cập nhật thông tin người dùng thất bại.\n' + response.message);
                }
            }))
                .catch(error => {
                this.isLoading = false;
                this._notifierService.showWarning(Utilities.getErrorDescription(error));
            });
        }
        else {
            const invalid = this.findInvalidControls();
            this._notifierService.showError(invalid, 'Vui lòng nhập đủ thông tin');
        }
        this.isLoading = false;
    }
    findInvalidControls() {
        const controls = this.formGroup.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                const ce = controls[name].errors;
                // if (ce.required) return `Vui lòng nhập ${this.getControlTitle(name)}`;
                if (ce.minlength)
                    return `${this.getControlTitle(name)} ít nhất ${ce.minlength.requiredLength} ký tự`;
                //if (ce.email) return `${this.getControlTitle(name)} sai định dạng`;
            }
        }
        return '';
    }
    closePopupMethod(data) {
        this.formGroup.reset();
        this.isShow = false;
        this.isGetLocation = false;
        this.closePopup.next(data);
    }
    getControlTitle(name) {
        switch (name) {
            case 'name':
                return 'Tên nhà thuốc/phòng khám/TDV';
            case 'phone':
                return 'Số điện thoại';
            case 'password':
                return 'Mật khẩu';
            //case 'email':
            //return 'Email';
            case 'idPharmacyType':
                return 'Loại tài khoản';
        }
    }
    accountTypeChange() {
        if (this.modelEdit.idType === UserType.NhaThuoc) {
            this.formGroup.get('idPharmacyType').setValidators([Validators.required]);
            this.loadUserGroupsNT();
        }
        else {
            this.formGroup.get('idPharmacyType').clearValidators();
        }
        this.formGroup.get('idPharmacyType').updateValueAndValidity();
    }
    buildTree(data, parentId = null) {
        const result = [];
        // Nếu parentId là null, ta sẽ tìm tất cả các mục có idParent là 0 (thằng cha to nhất)
        //const filteredData = parentId === null ? data.filter(item => item.idParent === 0) : data.filter(item => item.idParent === parentId);
        data
            .filter(item => item.idParent === parentId)
            .forEach(item => {
            const children = this.buildTree(data, item.id);
            const newNode = {
                idParent: item.idParent,
                id: item.id,
                name: item.name,
                checked: false,
                expanded: false,
                children: children.length ? children : undefined
            };
            result.push(newNode);
        });
        return result;
    }
    flattenTree(nodes, level = 0) {
        let result = [];
        nodes.forEach(node => {
            result.push({
                label: `${' _ '.repeat(level)} ${node.name}`,
                value: node.id
            });
            if (node.children) {
                result = result.concat(this.flattenTree(node.children, level + 1));
            }
        });
        return result;
    }
    viewLocation(lat, lng) {
        if (lat && lng) {
            const url = `https://www.google.com/maps?q=${lat},${lng}`;
            window.open(url, '_blank');
        }
        else {
            alert('Toạ độ không hợp lệ!!!.');
        }
    }
    formatDate() {
    }
    fn_CheckLocationLatLng(lat, lng) {
        const getJsonLoccationLatLng = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json&accept-language=vi`;
        fetch(getJsonLoccationLatLng)
            .then(response => response.json())
            .then(data => {
            const detailAddress = this.mapJsonToDetailAddress(data);
            this.addressLatLng = detailAddress.display_name;
        })
            .catch(error => console.error("CHECK LẠI LAT LNG"));
        this.isGetLocation = !this.isGetLocation;
    }
    onAdminApprove(item, e) {
        this._UserService.Approved(item.userId, e.checked).then(rs => {
            if (rs.status) {
                this._notifierService.showSuccess('Kích hoạt thành công');
            }
            else {
                this._notifierService.showError(rs.message);
            }
        });
    }
}
