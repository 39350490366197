import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../../lib-shared/services/base.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CheckInOutsService extends BaseService {
    constructor(http, injector) {
        super(http, injector, `${environment.apiDomain.dapFoodEndPoint}/CheckInOuts`);
    }
    Gets(key, userId, idClient, idCustomer, idProvince, idCustomerType, idUserGroup, isOrder, isActive, isImage, isAccepted, idRoute, idRouteMember, isCorrectRoute, fromDate, toDate, offset, limit, sortField, isAsc = false) {
        let fDate;
        let tDate;
        if (fromDate) {
            fDate = fromDate.toISOString();
        }
        if (toDate) {
            tDate = toDate.toISOString();
        }
        const queryString = `${this.serviceUri}?key=${key}&userId=${userId}&idClient=${idClient}&idCustomer=${idCustomer}&idProvince=${idProvince}&idCustomerType=${idCustomerType}&idUserGroup=${idUserGroup}&isOrder=${isOrder}&idRoute=${idRoute}&idRouteMember=${idRouteMember}&isActive=${isActive}&isImage=${isImage}&isAccepted=${isAccepted}&isCorrectRoute=${isCorrectRoute}&fromDate=${fDate}&toDate=${tDate}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
        return this.defaultGet(queryString);
    }
    GetByUserId(key, idCustomer, idRoute, idRouteMember, isCorrectRoute, fromDate, toDate, offset, limit, sortField, isAsc = false, userId) {
        let fDate;
        let tDate;
        if (fromDate) {
            fDate = fromDate.toISOString();
        }
        if (toDate) {
            tDate = toDate.toISOString();
        }
        let queryString = `${this.serviceUri}/GetByUserId?key=${key}&idCustomer=${idCustomer}&idRoute=${idRoute}&idRouteMember=${idRouteMember}&isCorrectRoute=${isCorrectRoute}&startDate=${fDate}&endDate=${tDate}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
        if (userId !== undefined) {
            queryString += `&userId=${userId}`;
        }
        return this.defaultGet(queryString);
    }
    ChangeAccepted(item) {
        return this._http
            .post(this.serviceUri + '/ChangeAccepted/' + item.id, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    ChangeActive(item) {
        return this._http
            .post(this.serviceUri + '/ChangeActive/' + item.id, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    CheckOut(item) {
        return this._http
            .post(this.serviceUri + '/CheckOut/' + item.id, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    UpdateDelete(item) {
        return this._http
            .post(this.serviceUri + '/UpdateDelete/' + item.id, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    FollowCheckInOutUser(key, userId, idUserGroupTdv, customerId, idProvince, idCustomerType, idUserGroup, isOrder, isImage, isCorrectRoute, fromDate, toDate, offset, limit, sortField, isAsc = false) {
        let fDate;
        let tDate;
        if (fromDate) {
            fDate = fromDate.toISOString();
        }
        if (toDate) {
            tDate = toDate.toISOString();
        }
        const queryString = `${this.serviceUri}/FollowCheckInOutUser?key=${key}&userId=${userId}&idUserGroupTdv=${idUserGroupTdv}&customerId=${customerId}&idProvince=${idProvince}&idCustomerType=${idCustomerType}&idUserGroup=${idUserGroup}&isOrder=${isOrder}&isImage=${isImage}&isCorrectRoute=${isCorrectRoute}&fromDate=${fDate}&toDate=${tDate}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
        return this.defaultGet(queryString);
    }
    GetCurrentCheckInDay(idTdv, key, lat, lng) {
        const queryString = `${this.serviceUri}/GetCurrentCheckInDay?idTdv=${idTdv}&key=${key}&lat=${lat}&lng=${lng}`;
        return this.defaultGet(queryString);
    }
    GetHistoryCheckInCustomer(idCustomer, fromDate, toDate) {
        let fDate;
        let tDate;
        fDate = fromDate ? fromDate.toISOString() : null;
        tDate = toDate ? toDate.toISOString() : null;
        const queryString = `${this.serviceUri}/GetHistoryCheckInCustomer?idCustomer=${idCustomer}&fromDate=${fDate}&toDate=${tDate}`;
        return this.defaultGet(queryString);
    }
}
CheckInOutsService.ngInjectableDef = i0.defineInjectable({ factory: function CheckInOutsService_Factory() { return new CheckInOutsService(i0.inject(i1.HttpClient), i0.inject(i0.INJECTOR)); }, token: CheckInOutsService, providedIn: "root" });
