import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../../lib-shared/services/base.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ProductAdsCheckInService extends BaseService {
    constructor(http, injector) {
        super(http, injector, `${environment.apiDomain.dapFoodEndPoint}/ProductAdsCheckIn`);
    }
    Gets(key, userId, idClient, idCustomer, idUserGroupTdv, idProductAds, idCheckInOut, idProvince, idCustomerType, idUserGroup, isActive, isAccepted, isPayReward, isRewarded, fromDate, toDate, totalSaleFrom, totalSaleTo, offset, limit, sortField, isAsc = false) {
        let fDate;
        let tDate;
        if (fromDate) {
            fDate = fromDate.toISOString();
        }
        if (toDate) {
            tDate = toDate.toISOString();
        }
        const queryString = `${this.serviceUri}?key=${key}&userId=${userId}&idClient=${idClient}&idCustomer=${idCustomer}&idUserGroupTdv=${idUserGroupTdv}&idProductAds=${idProductAds}&idCheckInOut=${idCheckInOut}&idProvince=${idProvince}&idCustomerType=${idCustomerType}&idUserGroup=${idUserGroup}&isActive=${isActive}&isAccepted=${isAccepted}&isPayReward=${isPayReward}&isRewarded=${isRewarded}&fromDate=${fDate}&toDate=${tDate}&totalSaleFrom=${totalSaleFrom}&totalSaleTo=${totalSaleTo}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
        return this.defaultGet(queryString);
    }
    ChangeAccepted(item) {
        return this._http
            .post(this.serviceUri + '/ChangeAccepted/' + item.id, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    DestroyAccepted(item) {
        return this._http
            .post(this.serviceUri + '/DestroyAccepted/' + item.id, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    MultiAccepted(ids, status) {
        return this._http
            .post(this.serviceUri + '/MultiAccepted?ids=' + ids + '&status=' + status, { ids: ids, status: status })
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    PayReward(item) {
        return this._http
            .post(this.serviceUri + '/PayReward/' + item.id, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    MultiPayReward(ids, status) {
        return this._http
            .post(this.serviceUri + '/MultiPayReward?ids=' + ids + '&status=' + status, { ids: ids, status: status })
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    ChangeActive(item) {
        return this._http
            .post(this.serviceUri + '/ChangeActive/' + item.id, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    UpdateDelete(item) {
        return this._http
            .post(this.serviceUri + '/UpdateDelete/' + item.id, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
ProductAdsCheckInService.ngInjectableDef = i0.defineInjectable({ factory: function ProductAdsCheckInService_Factory() { return new ProductAdsCheckInService(i0.inject(i1.HttpClient), i0.inject(i0.INJECTOR)); }, token: ProductAdsCheckInService, providedIn: "root" });
