import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../../lib-shared/services/base.service';
import { ResponseResult } from '../../lib-shared/models/response-result';

@Injectable({
    providedIn: 'root',
})
export class CheckInOutsService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.dapFoodEndPoint}/CheckInOuts`);
    }

    Gets(key: string, userId: number, idClient: number, idCustomer: number, idProvince: number, idCustomerType: number, idUserGroup: number, isOrder: number, isActive: number, isImage: number, isAccepted: number, idRoute: number, idRouteMember: number, isCorrectRoute: number, fromDate?: Date, toDate?: Date, offset?: number, limit?: number, sortField?: string, isAsc: boolean = false) {
        let fDate;
        let tDate;
        if (fromDate) {
            fDate = fromDate.toISOString();
        }
        if (toDate) {
            tDate = toDate.toISOString();
        }

        const queryString = `${this.serviceUri}?key=${key}&userId=${userId}&idClient=${idClient}&idCustomer=${idCustomer}&idProvince=${idProvince}&idCustomerType=${idCustomerType}&idUserGroup=${idUserGroup}&isOrder=${isOrder}&idRoute=${idRoute}&idRouteMember=${idRouteMember}&isActive=${isActive}&isImage=${isImage}&isAccepted=${isAccepted}&isCorrectRoute=${isCorrectRoute}&fromDate=${fDate}&toDate=${tDate}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
        return this.defaultGet(queryString);
    }

    GetByUserId(key: string, idCustomer: number, idRoute: number, idRouteMember: number, isCorrectRoute: number, fromDate?: Date, toDate?: Date, offset?: number, limit?: number, sortField?: string, isAsc: boolean = false, userId?: number) {
        let fDate;
        let tDate;

        if (fromDate) {
            fDate = fromDate.toISOString();
        }
        if (toDate) {
            tDate = toDate.toISOString();
        }

        let queryString = `${this.serviceUri}/GetByUserId?key=${key}&idCustomer=${idCustomer}&idRoute=${idRoute}&idRouteMember=${idRouteMember}&isCorrectRoute=${isCorrectRoute}&startDate=${fDate}&endDate=${tDate}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;

        if (userId !== undefined) {
            queryString += `&userId=${userId}`;
        }

        return this.defaultGet(queryString);
    }


    ChangeAccepted(item: any): Promise<ResponseResult> {
        return this._http
            .post<ResponseResult>(this.serviceUri + '/ChangeAccepted/' + item.id, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    ChangeActive(item: any): Promise<ResponseResult> {
        return this._http
            .post<ResponseResult>(this.serviceUri + '/ChangeActive/' + item.id, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    CheckOut(item: any): Promise<ResponseResult> {
        return this._http
            .post<ResponseResult>(this.serviceUri + '/CheckOut/' + item.id, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    UpdateDelete(item: any): Promise<ResponseResult> {
        return this._http
            .post<ResponseResult>(this.serviceUri + '/UpdateDelete/' + item.id, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    FollowCheckInOutUser(key: string, userId: number, idUserGroupTdv: number, customerId: number, idProvince: number, idCustomerType: number, idUserGroup: number, isOrder: number, isImage: number, isCorrectRoute: number, fromDate?: Date, toDate?: Date, offset?: number, limit?: number, sortField?: string, isAsc: boolean = false) {
        let fDate;
        let tDate;
        if (fromDate) {
            fDate = fromDate.toISOString();
        }
        if (toDate) {
            tDate = toDate.toISOString();
        }

        const queryString = `${this.serviceUri}/FollowCheckInOutUser?key=${key}&userId=${userId}&idUserGroupTdv=${idUserGroupTdv}&customerId=${customerId}&idProvince=${idProvince}&idCustomerType=${idCustomerType}&idUserGroup=${idUserGroup}&isOrder=${isOrder}&isImage=${isImage}&isCorrectRoute=${isCorrectRoute}&fromDate=${fDate}&toDate=${tDate}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
        return this.defaultGet(queryString);
    }

    GetCurrentCheckInDay(idTdv: number, key: string, lat: number, lng: number) {
        const queryString = `${this.serviceUri}/GetCurrentCheckInDay?idTdv=${idTdv}&key=${key}&lat=${lat}&lng=${lng}`;
        return this.defaultGet(queryString);
    }

    GetHistoryCheckInCustomer(idCustomer: number, fromDate?: Date, toDate?: Date) {
        let fDate;
        let tDate;
        fDate = fromDate ? fromDate.toISOString() : null;
        tDate = toDate ? toDate.toISOString() : null;
        const queryString = `${this.serviceUri}/GetHistoryCheckInCustomer?idCustomer=${idCustomer}&fromDate=${fDate}&toDate=${tDate}`;
        return this.defaultGet(queryString);
    }
}
